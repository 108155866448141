<template>
  <b-modal v-model="c_show" title="Add Contact" hide-footer @hide="onHide">
    <b-form>
      <b-form-group label="Name">
        <b-form-input id="i-user-name" type="text" v-model="form.name" placeholder="Input contact name" />
      </b-form-group>
      <b-form-group label="Phone Number">
        <b-input-group>
          <template #prepend>
            <el-select v-model="form.country_code" filterable size="small">
              <el-option v-for="(item, index) in country_code" :key="index" :value="item.dial_code" :label="item.dial_code + ' (' +  item.name +')'"/>
            </el-select>
          </template>
          <b-form-input class="ml-2" id="i-user-phone" type="text" v-model="form.phone_number" placeholder="Contact phone. eg: 8123456" />
        </b-input-group>
      </b-form-group>
      <b-form-group>
        <label for="input-email">Label</label><br/>
        <el-select class="w-100" :loading="loading.label" :loading-text="$t('general.loading')" @change="selectedLabelChanged" multiple size="small" v-model="selected_label">
          <el-option v-for="(label) in label_list" :key="label.id" :value="label.id"
            :label="label.title"
          />
        </el-select>
      </b-form-group>
      <b-form-group :label="v.label" :key="v.key" v-for="(v,i) in custom_fields">
        <b-form-input :id="`i-${v.key}`" type="text" v-model="form.custom_data[i].value" :placeholder="`Input ${v.label}`" />
      </b-form-group>
    </b-form>
    <div class="d-flex flex-row mt-2 float-right">
      <b-button type="submit" @click="onSubmit" variant="primary" class="mr-2">{{ $t('general.submit') }}</b-button>
      <b-button type="reset" @click="onHide" variant="secondary">{{ $t('general.cancel') }}</b-button>
    </div>
  </b-modal>
</template>
<script>
import labels from '@/api/labels';
import contactsApi from '@/api/contacts';
import country_code from '../../../library/full-country-code.json';

export default {
  name: 'ContactsSingleCreate',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        name: '',
        phone_number: '',
        country_code: '+62',
        custom_data: [],
      },
      country_code,
      c_show: false,
      loading: {
        label: false,
        field: false,
      },
      label_list: [],
      custom_fields: [],
      selected_label: [],
    };
  },
  computed: {
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
  },
  mounted() {
    this.form = {
      name: '',
      phone_number: '',
      country_code: '+62',
      custom_data: [],
    };
  },
  methods: {
    onHide() {
      this.c_show = false;
      this.$emit('onHide');
    },
    async getLabelList() {
      this.loading.label = true;
      await labels.getList(this.activeWorkspace._id)
        .then((response) => {
          this.label_list = response.data.rows;
        });
      this.loading.label = false;
    },
    async getCustomFields() {
      this.loading.field = true;
      await contactsApi.get_field({
        workspace_id: this.activeWorkspace._id,
      }).then((response) => {
        this.custom_fields = response.data.fields;
        this.custom_fields.forEach((v) => {
          this.form.custom_data.push({
            key: v.key,
            value: '',
          });
        });
      });
      this.loading.field = false;
    },
    selectedLabelChanged() {},
    onSubmit() {
      let str = 'phone;name\n';
      str += `${this.form.country_code}${this.form.phone_number};${this.form.name}`;
      const data = {
        file: str,
        selected_label: this.selected_label || [],
      };
      const custom_data_filled = this.form.custom_data.filter((v) => v.value);
      if (custom_data_filled.length > 0) {
        data.custom_data = custom_data_filled;
      }
      this.$emit('onSubmit', data);
    },
  },
  watch: {
    show() {
      this.c_show = this.show;
      this.getLabelList();
      this.getCustomFields();
    },
  },
};
</script>
