<template>
  <div class="navbar-content align-items-center shadow-lg bg-dark text-light border-dark" v-if="showModalMinimizeCall">
    <a class="text-light m-2" @click="maximizeCall()">
      <i class="mdi mdi-arrow-expand mdi-24px"></i>
    </a>
    <div class="flex-container">
      <div class="text-left">
        <p class="h5 font-weight-bold"> {{ phone_call.name }} </p>
        <p class="font-weight-light"> {{ phone_call.phone_number }} </p>
      </div>
    </div>
    <div class="align-items-center text-center">
      <p class="h5 font-weight-bold"> {{ phone_call.channel }} </p>
      <p class="font-weight-bold text-secondary" v-if="call.duration != 0"> {{ call.duration }} </p>
      <p class="font-weight-bold text-secondary" v-else> {{ phone_call.status }} </p>
    </div>
    <ul class="navbar-nav text-center" v-if="call.duration == 0">
      <li class="nav-item">
        <el-button circle class="btn btn-danger btn-icon btn-xs float-left ml-4" @click="handleEndPhoneCall">
          <i class="mdi mdi-phone-hangup"></i>
        </el-button>
      </li>
      <li class="nav-item" v-if="call_session.originator === 'remote'">
        <el-button circle class="btn btn-success btn-icon btn-xs float-right mr-4" @click="handleAnswerIncomingCall">
          <i class="mdi mdi-phone"></i>
        </el-button>
      </li>
    </ul>
    <ul class="navbar-nav text-center" v-if="call.duration !== 0">
      <!-- <li class="nav-item">
        <el-button circle class="btn btn-secondary btn-icon btn-xs right-px" @click="handleVolumePhoneCall">
          <i :class="phone_call.volume"></i>
        </el-button>
      </li> -->
      <li class="nav-item">
        <el-button circle class="btn btn-secondary btn-icon btn-xs" @click="handlerMicPhoneCall">
          <i :class="phone_call.mic"></i>
        </el-button>
      </li>
      <li class="nav-item">
        <el-button circle class="btn btn-danger btn-icon btn-xs" @click="handleEndPhoneCall">
          <i class="mdi mdi-phone-hangup mdi-24px"></i>
        </el-button>
      </li>
    </ul>
  </div>
</template>

<script>
// import moment from 'moment';
// import JsSIP from 'jssip';
import { toSvg } from 'jdenticon';
// import popupErrorMessages from '@/library/popup-error-messages';

export default {
  name: 'PreviewMinimizeCall',
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    call_session: {
      type: Object,
      default() {
        return {};
      },
    },
    phone_call: {
      type: Object,
      default() {
        return {};
      },
    },
    call: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      showModalMinimizeCall: false,
      isMutedCall: false,
    };
  },
  methods: {
    identicons(value) {
      return toSvg(value, '75');
    },
    onHide() {
      this.$emit('onHide');
    },
    handlerMicPhoneCall() {
      this.isMutedCall = !this.isMutedCall;
      console.log('this.isMutedCall', this.isMutedCall);
      this.phone_call.mic = this.isMutedCall ? 'mdi mdi-microphone-off mdi-24px' : 'mdi mdi-microphone mdi-24px';
      this.$emit('handleMicPhoneCall');
    },
    handleVolumePhoneCall() {
      if (this.phone_call.volume === 'mdi mdi-volume-low mdi-24px') {
        this.phone_call.volume = 'mdi mdi-volume-source mdi-24px';
      } else {
        this.phone_call.volume = 'mdi mdi-volume-low mdi-24px';
      }
    },
    handleAnswerIncomingCall() {
      this.$parent.handleAnswerIncomingCall();
    },
    handleEndPhoneCall() {
      this.$parent.handleEndPhoneCall();
    },
    maximizeCall() {
      this.$parent.handleMaximizePhoneCall();
    },
  },
  watch: {
    show() {
      this.showModalMinimizeCall = this.show;
    },
  },
};
</script>

<!-- <style>
.bg-dark {
  background-color: #101623 !important;
}
.modal-content {
  border: 2px solid #474747;
  background-color: #101623;
  border-radius: 1.135rem;
}
.modal-header {
  /* border-bottom: 1px solid #474747; */
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}
.modal-body {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}
.border-header-call {
  border-bottom: 2px solid #474747 !important;
}
</style> -->
<style>
.flex-container {
  display: flex;
  justify-content: space-between;
  margin-inline-end: auto;
}
.main-wrapper .page-wrapper .page-content .minimize-call {
  margin-top: 105px;
}
</style>
