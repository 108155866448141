<template>
  <div class="page-content">
    <div class="row chat-wrapper">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-md-4">
                <form class="search-form">
                  <div class="input-group border rounded-sm">
                    <div class="input-group-prepend">
                      <div class="input-group-text border-0 rounded-sm">
                        <FeatherIcon type="search" class="icon-md cursor-pointer" />
                      </div>
                    </div>
                    <form @submit.prevent="searchContact">
                      <input v-model="search" type="text" class="form-control  border-0 rounded-sm" id="searchForm" placeholder="Search here...">

                    </form>
                  </div>
                </form>
              </div>
              <div class="col-md-2">
                <el-select class="w-100" id="labels" @change="doSearch()" v-model="channel_type">
                  <el-option key="" label="All Channel" value=""></el-option>
                  <el-option v-for="dt in channel_list" :key="dt.id" :label="dt.title" :value="dt.id"></el-option>
                </el-select>
              </div>
              <div class="col-md-2">
                <el-select class="w-100" id="labels" @change="doSearch()"
                  filterable
                  :filter-method="handlerSearchLabel" v-model="label_id">
                  <el-option key="" label="All Labels" value=""></el-option>
                  <el-option v-for="dt in label_list" :key="dt.id" :label="dt.title" :value="dt.id"></el-option>
                </el-select>
              </div>
              <div class="col-md-4">
                <el-button class="float-right btn-primary" size="small" @click="handlerClickUploadBtn">
                  <font-awesome icon="upload"/>&nbsp; Upload
                </el-button>
                <el-button class="float-right btn-secondary mr-2" size="small" @click="handlerClickAddBtn">
                  <font-awesome icon="plus"/>&nbsp; Add New Contact
                </el-button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row position-relative">
              <div class="col-lg-4 chat-aside border-lg-right">
                <div class="aside-content">
                  <!-- <div class="aside-header">
                    <form class="search-form">
                      <div class="input-group border rounded-sm">
                        <div class="input-group-prepend">
                          <div class="input-group-text border-0 rounded-sm">
                            <FeatherIcon type="search" class="icon-md cursor-pointer" />
                          </div>
                        </div>
                        <form @submit.prevent="searchContact">
                          <input v-model="search" type="text" class="form-control  border-0 rounded-sm" id="searchForm" placeholder="Search here...">
                        </form>
                      </div>
                    </form>
                  </div> -->
                  <div class="aside-body">
                    <!-- <p class="text-muted mt-3">Contacts</p> -->
                    <ul class="nav nav-tabs mt-1" ref="tabStatusConvo" id="tabStatusConvo" role="tablist"
                      @click="tabSettingClicked">
                      <li class="nav-item">
                        <a class="nav-link active" ref="contacts-tab" id="contacts-tab" data-toggle="tab" href="#contacts" role="tab" aria-controls="contacts" aria-selected="true">
                          <div class="d-flex flex-row flex-lg-column flex-xl-row align-items-center">
                            <p id="contacts-title">Contacts ({{ totalRow || 0 }})</p>
                          </div>
                        </a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" ref="block-contacts-tab" id="block-contacts-tab" data-toggle="tab" href="#block-contacts" role="tab" aria-controls="block-contacts" aria-selected="false">
                          <div class="d-flex flex-row flex-lg-column flex-xl-row align-items-center">
                            <p id="block-contacts-title">Blocked Contacts ({{ totalRowBlockContact || 0 }})</p>
                          </div>
                        </a>
                      </li>
                    </ul>
                    <div class="tab-content mt-1" @scroll="onScrollContact">
                      <div class="tab-pane fade show active" id="contacts" role="tabpanel" aria-labelledby="contacts-tab" v-loading="loading.contact" style="min-height: 200px">
                        <ul class="list-unstyled chat-list px-1">
                          <li v-for="(data) in contactList" :key="data.id" class="chat-item pr-1" @click="showDetailContact(data)">
                            <a href="javascript:;" class="d-flex align-items-center">
                              <div :data-initials-contact="initials(data.contact.name)"/>
                              <div class="d-flex align-items-center justify-content-between flex-grow border-bottom">
                                <div>
                                  <p class="text-body">{{ data.contact.name }}</p>
                                  <div class="d-flex align-items-center">
                                    <p class="text-muted tx-13">({{ data.contact.display_name }})</p>
                                  </div>
                                </div>
                                <div class="d-flex align-items-end text-body">
                                  <!-- <FeatherIcon type="message-square" class="icon-md text-secondary mr-2" /> -->
                                </div>
                              </div>
                            </a>
                          </li>
                        </ul>
                        <div v-loading="loading.load_more" v-if="isMoreResult" align="center">
                            <a href="#" @click="infiniteHandler">Load more</a>
                          </div>
                          <div v-if="!isMoreResult && totalRow != 0" align="center">
                            <p>No more contacts</p>
                          </div>
                        <el-empty v-if="totalRow == 0" description="No data"></el-empty>
                      </div>
                      <div class="tab-pane fade show" id="block-contacts" role="tabpanel" aria-labelledby="block-contacts-tab" v-loading="loading.block_contact" style="min-height: 200px">
                        <ul class="list-unstyled chat-list px-1">
                          <li v-for="(data) in blockContactList" :key="data.id" class="chat-item pr-1" @click="showDetailContact(data)">
                            <a href="javascript:;" class="d-flex align-items-center">
                              <div :data-initials-contact="initials(data.contact.name)"/>
                              <div class="d-flex align-items-center justify-content-between flex-grow border-bottom">
                                <div>
                                  <p class="text-body">{{ data.contact.name }}</p>
                                  <div class="d-flex align-items-center">
                                    <!-- <p class="text-muted tx-13">Front-end Developer</p> -->
                                  </div>
                                </div>
                                <div class="d-flex align-items-end text-body">
                                  <!-- <FeatherIcon type="message-square" class="icon-md text-secondary mr-2" /> -->
                                </div>
                              </div>
                            </a>
                          </li>
                        </ul>
                        <div v-loading="loading.load_more_block_contact" v-if="isMoreResultBlockContact" align="center">
                            <a href="#" @click="infiniteHandlerBlockContact">Load more</a>
                          </div>
                          <div v-if="!isMoreResultBlockContact && totalRowBlockContact != 0" align="center">
                            <p>No more contacts</p>
                          </div>
                        <el-empty v-if="totalRowBlockContact == 0" description="No data"></el-empty>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-loading="loading.contact_detail" v-if="selectedContact" class="col-lg-8 chat-content px-4" :class="{ 'show' : showDetail }">
                <!-- <div :data-initials-contact="initials(selectedContact.contact.name || selectedContact.name)"/> -->
                <div class="row mb-4">
                  <div class="col-lg-12">
                    <b-button :variant="buttonCallStyle" size="sm" type="button" v-if="isEnableVoIPFeature && !(selectedContact.contact.is_blocked || selectedContact.is_blocked) && (selectedContact.contact.channel || selectedContact.channel) == 'phone_call'" :disabled="!isAgent || !this.userProfile.agents[0].extension || !buttonCallActive" @click="handleCall()">
                      <font-awesome icon="phone"/>&nbsp; Call via Mobile
                    </b-button>
                    <b-button variant="outline-danger ml-1 float-right" size="sm" type="button" v-if="!selectedContact.contact.is_blocked" @click="blockContact">Block Contact</b-button>
                    <b-button variant="outline-success ml-1 float-right" size="sm" v-else @click="unblockContact">Unblock Contact</b-button>
                    <b-button class="ml-1 float-right" v-if="upperFirst(selectedContact.contact.channel || selectedContact.channel) == 'Whatsapp' && !validatePhoneNumber()" variant="outline-danger" size="sm" type="button" @click="deleteContact">Delete Contact</b-button>
                    <b-button class="ml-1 float-right" variant="outline-primary" size="sm" type="button" @click="handlerClickEditBtn">Edit Contact</b-button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4">
                    <p class="font-weight-bold">Name</p>
                    <p class="text-muted mb-3">{{ selectedContact.contact.name || selectedContact.name }}</p>
                    <p class="font-weight-bold">Display Name</p>
                    <p class="text-muted mb-3">{{ selectedContact.contact.display_name || selectedContact.name || '-' }}</p>
                    <!-- <p class="font-weight-bold">Email</p>
                    <p class="text-muted mb-3">-</p> -->
                    <p class="font-weight-bold">Phone Number</p>
                    <p class="text-muted mb-3">{{ selectedContact.phone_number_str }}</p>
                    <p class="font-weight-bold">Source Channel</p>
                    <p class="text-muted mb-3">{{ upperFirst(channel_name(selectedContact.contact.channel || selectedContact.channel)) }}</p>
                    <div class="row">
                      <div class="col-lg-12">
                        <p class="font-weight-bold">Status</p>
                        <div class="text-muted mb-3">
                          <!-- <b-badge :variant="(selectedContact.contact.is_blocked || selectedContact.is_blocked) ? 'danger' : 'success'">{{ (selectedContact.contact.is_blocked || selectedContact.is_blocked) ? 'Blocked' : 'Unblocked' }}</b-badge> -->
                          <b-badge :variant="(selectedContact.contact.is_blocked || selectedContact.is_blocked) ? 'danger' : 'success'">{{ (selectedContact.contact.is_blocked || selectedContact.is_blocked) ? 'Blocked' : 'Unblocked' }}</b-badge>
                          <!-- <template v-if="!selectedContact.contact.is_blocked && !selectedContact.is_blocked"> -->
                        </div>
                        <div v-if="selectedContact.contact.is_blocked">
                          <p class="font-weight-bold">Reason</p>
                          <div class="mb-2">
                            <span>{{ selectedContact.contact.blocked_reason || selectedContact.blocked_reason || '-' }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4" v-if="selectedContactFields">
                    <div v-for="(v, idx) in selectedContactFields.filter((v, i) => i < 5)" :key="idx">
                      <p class="font-weight-bold">{{ v.label }}</p>
                      <p class="text-muted mb-3">{{ selectedContactCustomData ? getValueCustomField(v.key) : '-' }}</p>
                    </div>
                    <div>
                      <p class="font-weight-bold">Groups</p>
                      <p class="mb-2">
                        <template v-if="selectedContact.contact && selectedContact.contact.groups && selectedContact.contact.groups.length > 0">
                          <el-tag
                            class="mr-2 mb-2"
                            v-for="(group) in selectedContact.contact.groups"
                            :key="group.id"
                            :closable="false"
                            size="small"
                            :style="{ 'background-color': 'white', 'color': 'black' }"
                            effect="dark">
                            {{ group.name }}
                          </el-tag>
                        </template>
                        <span v-else>-</span>
                      </p>
                    </div>
                  </div>
                  <div class="col-lg-4" v-if="selectedContactFields">
                    <div v-for="(v, idx) in selectedContactFields.filter((v, i) => i >= 5)" :key="idx">
                      <p class="font-weight-bold">{{ v.label }}</p>
                      <p class="text-muted mb-3">{{ selectedContactCustomData ? getValueCustomField(v.key) : '-' }}</p>
                    </div>
                    <div>
                      <p class="font-weight-bold">Labels</p>
                      <p class="mb-2">
                        <template v-if="selectedContact.labels && selectedContact.labels.length > 0">
                          <el-tag
                            class="mr-2 mb-2"
                            v-for="(label) in selectedContact.labels"
                            :key="label.id"
                            :closable="false"
                            size="small"
                            :style="{ 'background-color': label.color, 'color': 'white' }"
                            effect="dark">
                            {{ label.title }}
                          </el-tag>
                        </template>
                        <span v-else>-</span>
                      </p>
                    </div>
                  </div>
                </div>
                <!-- <pre>{{ selectedContact }}</pre> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="show_modal.upload" title="Upload Contact" hide-footer @hide="handlerHideModalUpload">
      <p class="mb-2">
        To upload contact, use must input <code>.csv</code> file. Your file should contain 2 mandatory column <code>phone</code> and <code>name</code>. Or, you can download <a href="#" @click="downloadCsv()">this example</a> for your reference.
      </p>
      <b-form>
        <b-form-group>
          <input type="file" class="dropify" accept=".csv" v-on:input="handlerInputRecipientFile"/>
        </b-form-group>
        <b-form-group>
          <label for="input-email">Label</label><br/>
          <el-select class="w-100" :loading="loading.label" :loading-text="$t('general.loading')" @change="selectedLabelChanged" multiple size="small" v-model="selected_label">
            <el-option v-for="(label) in label_list" :key="label.id" :value="label.id"
              :label="label.title"
            />
          </el-select>
        </b-form-group>
      </b-form>
      <div class="d-flex flex-row mt-2 float-right">
        <b-button type="submit" @click="submitUpload" variant="primary" class="mr-2">{{ $t('general.submit') }}</b-button>
        <b-button type="reset" @click="handlerHideModalUpload" variant="secondary">{{ $t('general.cancel') }}</b-button>
      </div>
    </b-modal>
    <contacts-single-create :show="show_modal.single_add" @onSubmit="handlerSubmitSingle" @onHide="show_modal.single_add = false" />
    <contacts-single-edit
      :show="show_modal.single_edit"
      :selectedContact="selectedContact"
      :selectedContactFields="selectedContactFields"
      :selectedContactCustomData="selectedContactCustomData"
      @onSubmit="handlerSubmitSingleEdit"
      @onHide="show_modal.single_edit = false"
    />
  </div>
</template>

<script>
/* eslint-disable no-undef */
import popupErrorMessages from '@/library/popup-error-messages';
import PerfectScrollbar from 'perfect-scrollbar';
// import axios from 'axios';
import _ from 'lodash';
import { phone } from 'phone';
import contactsApi from '../../api/contacts';
import labels from '../../api/labels';
import SingleCreate from '../components/contacts/SingleCreate.vue';
import SingleEdit from '../components/contacts/SingleEdit.vue';
import { ui_call_socket } from '../components/Navbar.vue';

export default {
  name: 'Contacts',
  metaInfo: {
    title: 'Contacts',
  },
  components: {
    ContactsSingleCreate: SingleCreate,
    ContactsSingleEdit: SingleEdit,
  },
  data() {
    return {
      loading: {
        contact: true,
        contact_detail: true,
        contact_delete: false,
        contact_save: false,
        load_more: false,
        label: false,
        // block contacts
        block_contact: false,
        load_more_block_contact: false,
      },
      show_modal: {
        upload: false,
        single_add: false,
        single_edit: false,
      },
      perfectScroll: [],
      showDetail: false,
      loaderStack: 0,
      loader: null,
      page: 1,
      search: '',
      label_id: '',
      channel_type: '',
      contactList: [],
      selectedContact: null,
      selectedContactCustomData: null,
      selectedContactFields: [],
      totalRow: 0,
      isMoreResult: true,
      drEvent: {},
      mandatoryHeader: [
        {
          value: 'phone',
        },
        {
          value: 'name',
        },
      ],
      // sample_csv: 'https://is3.cloudhost.id/makna-bucket/samples/Sample-Import-Contact.csv',
      recipient_file: null,
      label_list: [],
      selected_label: [],
      // block contacts
      pageBlockContact: 1,
      blockContactList: [],
      selectedBlockContact: null,
      totalRowBlockContact: 0,
      isMoreResultBlockContact: false,
      active_tab: 'contacts',
      blocked_reason: '',
      channel_list: [
        { id: 'whatsapp', title: 'Whatsapp' },
        { id: 'phone_call', title: 'Phone Call' },
        { id: 'instagram', title: 'Instagram' },
        { id: 'telegram', title: 'Telegram' },
        { id: 'fb_messenger', title: 'FB Messenger' },
      ],
      phone_call: {
        status: '',
        mic: 'mdi mdi-microphone mdi-24px',
        volume: 'mdi mdi-volume-low mdi-24px',
        name: '',
        phone_number: '',
      },
      custom_data: [],
      userProfile: this.$store.state.auth.userProfile,
    };
  },
  computed: {
    buttonCallStyle() {
      return ui_call_socket.contact_call_color;
    },
    buttonCallActive() {
      return ui_call_socket.button_call_active;
    },
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
    isAgent() {
      if (!_.isEmpty(this.userProfile.agents[0])) {
        const agent_data = this.userProfile.agents[0];
        if (agent_data.assigned_call_feature === true && agent_data.is_available === true) {
          return true;
        }
        return false;
      }
      return false;
    },
    isEnableCallFeature() {
      if (this.activeWorkspace._id && this.activeWorkspace.products && this.userProfile.agents[0]?.workspace_id === this.activeWorkspace._id) {
        return this.activeWorkspace.products[0].pricing.whatsapp.enable_call_feature;
      }
      return false;
    },
    isEnableVoIPFeature() {
      if (this.activeWorkspace._id && this.activeWorkspace.products && this.userProfile.agents[0]?.workspace_id === this.activeWorkspace._id) {
        return this.$store.state.auth.sip?.access?.extension ? this.activeWorkspace.products[0].pricing.phone_call.enable_feature : false;
      }
      return false;
    },
  },
  async mounted() {
    if (document.querySelectorAll('.chat-aside .tab-content').length) {
      this.perfectScroll.push(new PerfectScrollbar('.chat-aside .tab-content'));
    }
    if (document.querySelectorAll('.chat-content .chat-body').length) {
      this.perfectScroll.push(new PerfectScrollbar('.chat-content .chat-body'));
      const el = document.querySelector('.chat-content .chat-body');
      el.scrollTo(0, el.scrollHeight);
    }
    this.showLoader();
    this.getLabelList();
    this.getListBlockContact();
    await this.getListContact();
    if (this.contactList[0]) await this.contactDetail(this.contactList[0].id);
    this.hideLoader();
  },
  methods: {
    doSearch() {
      this.page = 1;
      this.contactList = [];
      this.blockContactList = [];
      this.getListBlockContact();
      this.getListContact();
    },
    // validatePhoneNumber() {
    //   const phone_number = this.selectedContact.contact.phone_number || selectedContact.phone_number;
    //   const cc = phone(`+${phone_number}`);
    //   return cc.isValid;
    // },
    validatePhoneNumber() {
      const phone_number = this.selectedContact.contact.phone_number || selectedContact.phone_number;
      const validateRegex = /^\+?[1-9][0-9]{7,14}$/;
      return validateRegex.test(`+${phone_number}`);
    },
    async getLabelList(search_keyword = null) {
      this.loading.label = true;
      await labels.getList(this.activeWorkspace._id, { search_keyword })
        .then((response) => {
          this.label_list = response.data.rows;
        });
      this.loading.label = false;
    },
    getValueCustomField(key) {
      if (this.selectedContactCustomData) {
        if (this.selectedContactCustomData[key]) {
          return this.selectedContactCustomData[key];
        }
      }
      return '-';
    },
    upperFirst(string) {
      return _.upperFirst(string);
    },
    tabSettingClicked(e) {
      const id = e.target.id.replace(/-title|-tab/ig, '');
      switch (id) {
        case 'contacts':
          this.isMoreResult = true;
          this.totalRow = 0;
          this.contactList = [];
          this.page = 1;
          this.getListContact();
          break;
        case 'block-contacts':
          this.isMoreResultBlockContact = true;
          this.totalRowBlockContact = 0;
          this.blockContactList = [];
          this.pageBlockContact = 1;
          this.getListBlockContact();
          break;
        default:
          break;
      }
      this.active_tab = id;
    },
    showLoader() {
      if (this.loaderStack === 0) {
        this.loader = this.$loading.show();
      }
      this.loaderStack += 1;
    },
    hideLoader() {
      this.loaderStack -= 1;
      if (this.loaderStack === 0) {
        this.loader.hide();
      }
    },
    showDetailContact(contact) {
      this.showDetail = true;
      this.blocked_reason = '';
      // if (this.active_tab === 'contacts') {
      this.contactDetail(contact.id);
      // } else {
      //   this.selectedContact = contact;
      // }
    },
    hideDetailContact() {
      this.showDetail = false;
    },
    channel_name(channel) {
      return channel.replace('_', ' ');
    },
    async searchContact() {
      switch (this.active_tab) {
        case 'contacts':
          this.isMoreResult = true;
          this.totalRow = 0;
          this.contactList = [];
          this.page = 1;
          this.getListContact();
          break;
        case 'block-contacts':
          this.isMoreResultBlockContact = true;
          this.totalRowBlockContact = 0;
          this.blockContactList = [];
          this.pageBlockContact = 1;
          this.getListBlockContact();
          break;
        default:
          break;
      }
    },
    async getListContact() {
      this.loading.contact = true;
      await contactsApi.getList({
        workspace_id: this.activeWorkspace._id,
        page: this.page,
        search: this.search,
        search_by: 'name',
        label_id: this.label_id,
        channel: this.channel_type,
      })
        .then((response) => {
          this.contactList.push(...response.data.rows);
          this.contactList = _.uniqBy(this.contactList, 'id');
          this.totalRow = response.data.count;
          if (this.contactList.length >= response.data.count) {
            this.isMoreResult = false;
          }
        })
        .catch(() => {});
      this.loading.contact = false;
    },
    async getListBlockContact() {
      this.loading.block_contact = true;
      await contactsApi.getListBlockContact({
        workspace_id: this.activeWorkspace._id,
        page: this.pageBlockContact,
        search: this.search,
        search_by: 'name',
        label_id: this.label_id,
        channel: this.channel_type,
      })
        .then((response) => {
          this.blockContactList.push(...response.data.rows);
          this.blockContactList = _.uniqBy(this.blockContactList, 'id');
          this.totalRowBlockContact = response.data.count;
          if (this.blockContactList.length >= response.data.count) {
            this.isMoreResultBlockContact = false;
          }
        })
        .catch(() => {});
      this.loading.block_contact = false;
    },
    initials(string) {
      return (string || '').split(' ').map((n) => n[0]).join('').slice(0, 2);
    },
    async contactDetail(id) {
      this.loading.contact_detail = true;
      const response = await contactsApi.detail({
        workspace_id: this.activeWorkspace._id,
        id,
      });
      this.selectedContact = response.data.contact;
      this.selectedContactFields = response.data.fields;
      this.selectedContactCustomData = response.data.custom_data;
      const phone_number = this.selectedContact.contact.phone_number || selectedContact.phone_number;
      const phone_number_str = `+${phone_number}`;
      const cc = phone(`+${phone_number}`);
      if (cc.isValid) {
        this.selectedContact.phone_number_str = phone_number_str.replace(cc.countryCode, `${cc.countryCode} `);
      } else {
        this.selectedContact.phone_number_str = phone_number;
      }
      this.loading.contact_detail = false;
    },
    onScrollContact(e) {
      if (Math.ceil(e.srcElement.offsetHeight + e.srcElement.scrollTop) >= e.srcElement.scrollHeight) {
        if (this.isMoreResult && !this.loading.load_more) {
          this.infiniteHandler();
        }
      }
    },
    async infiniteHandler() {
      this.page += 1;
      this.loading.load_more = true;
      await this.getListContact();
      this.loading.load_more = false;
    },
    selectedLabelChanged() {},
    handlerClickAddBtn() {
      this.show_modal.single_add = true;
    },
    async infiniteHandlerBlockContact() {
      this.pageBlockContact += 1;
      this.loading.load_more_block_contact = true;
      await this.getListBlockContact();
      this.loading.load_more_block_contact = false;
    },
    async blockContact() {
      this.$prompt('Are you sure to block this contact? You will no longer receive message from this contact.', 'Block this contact?', {
        inputPlaceholder: 'Write reason why you block this contact',
        type: 'warning',
        center: true,
        confirmButtonText: this.$t('general.yes'),
        cancelButtonText: this.$t('general.no'),
      }).then(async ({ value }) => {
        this.blocked_reason = value;
        this.loading.contact_detail = true;
        await contactsApi.blockContact({
          contact_id: this.selectedContact.contact.id || this.selectedContact.id,
          blocked_reason: this.blocked_reason,
        }).then((resp) => {
          if (!resp.error) {
            // to reset list contact
            this.searchContact();
            this.showDetailContact(this.selectedContact);
            this.$message({
              type: 'success',
              message: 'Successfully block contact',
            });
          } else {
            this.$message({
              type: 'error',
              message: resp.error.message || 'Something wrong, please try again.',
            });
          }
        }).catch(() => {
          this.$message({
            type: 'error',
            message: 'Something wrong, please try again.',
          });
        });
        this.loading.contact_detail = false;
      }).catch(() => {});
    },
    async deleteContact() {
      this.$confirm('Are you sure want to delete this contact? You will no longer see this contact.', 'Delete this contact?', {
        type: 'warning',
        center: true,
        confirmButtonText: this.$t('general.yes'),
        cancelButtonText: this.$t('general.no'),
        beforeClose: async (action, instance, cb) => {
          if (action === 'confirm') {
            this.loading.contact_delete = true;
            const response = await contactsApi.deleteContact({
              contact_id: this.selectedContact.contact.id || this.selectedContact.id,
              workspace_id: this.activeWorkspace._id,
            });
            this.loading.contact_delete = false;
            await popupErrorMessages(response);

            await this.searchContact();
            if (this.contactList[0]) await this.contactDetail(this.contactList[0].id);
            this.$message({
              type: 'success',
              message: 'Successfully delete contact',
            });
            instance.confirmButtonLoading = false;
            cb();
            return;
          }
          instance.confirmButtonLoading = false;
          cb();
        },
      }).catch(() => {});
    },
    async unblockContact() {
      this.$confirm('Are you sure to unblock this contact?', 'Unlock this contact?', {
        confirmButtonText: this.$t('general.yes'),
        cancelButtonText: this.$t('general.no'),
        type: 'warning',
        center: true,
      }).then(async () => {
        this.loading.contact_detail = true;
        await contactsApi.unblockContact({
          contact_id: this.selectedContact.contact.id || this.selectedContact.id,
        }).then((resp) => {
          if (!resp.error) {
            // to reset list contact
            this.searchContact();
            this.showDetailContact(this.selectedContact);
            this.$message({
              type: 'success',
              message: 'Successfully unblock contact',
            });
          } else {
            this.$message({
              type: 'error',
              message: resp.error.message || 'Something wrong, please try again.',
            });
          }
        }).catch(() => {
          this.$message({
            type: 'error',
            message: 'Something wrong, please try again.',
          });
        });
        this.loading.contact_detail = false;
      }).catch(() => {});
    },
    handlerClickUploadBtn() {
      this.show_modal.upload = true;
      this.getLabelList();
      this.$nextTick(() => {
        this.drEvent = $('.dropify').dropify({
          messages: {
            default: 'Drag and drop a file here or click',
            replace: 'Drag and drop or click to replace',
            remove: 'Remove',
            error: 'Ooops, something wrong happended.',
          },
        });
      });
    },
    handlerHideModalUpload() {
      this.show_modal.upload = false;
      this.selected_label = [];
      $('.dropify-clear').click();
    },
    handlerInputRecipientFile() {
      const target_file = this.drEvent[0].files[0];
      this.recipient_file = target_file;
    },
    async submitUpload() {
      this.showLoader();
      const target_file = this.recipient_file;
      const formData = new FormData();
      formData.append('file', target_file);
      this.selected_label.forEach((val) => {
        formData.append('labels_id[]', val);
      });
      if (this.custom_data) {
        this.custom_data.forEach((val) => {
          formData.append('custom_data[]', JSON.stringify(val));
        });
      }
      await contactsApi.importContact(this.activeWorkspace._id, formData).then(() => {
        this.$message({
          type: 'success',
          message: this.$t('contact.import_success'),
          showClose: true,
        });
        this.show_modal.single_add = false;
        this.handlerHideModalUpload();
        this.getListContact();
      }).catch((error) => {
        popupErrorMessages(error);
      });
      this.hideLoader();
    },
    handlerSearchLabel(value) {
      clearTimeout(this.timeout_search_user);
      this.timeout_search_user = setTimeout(() => {
        this.getLabelList(value);
      }, 500);
    },
    handlerClickEditBtn() {
      this.show_modal.single_edit = true;
    },
    handlerSubmitSingle(data) {
      this.selected_label = data.selected_label;
      this.custom_data = data.custom_data;
      const csv_blob = new Blob([data.file], { type: 'text/csv' });
      this.recipient_file = new File([csv_blob], 'data.csv', { type: 'text/csv' });
      this.submitUpload();
    },
    async handlerSubmitSingleEdit(data) {
      this.showLoader();
      const response = await contactsApi.update({
        contact_id: this.selectedContact.contact.id || this.selectedContact.id,
        workspace_id: this.activeWorkspace._id,
        data,
      });
      this.hideLoader();
      await popupErrorMessages(response);
      this.show_modal.single_edit = false;
      this.showDetailContact(this.selectedContact);
      this.$message({
        type: 'success',
        message: 'Successfully update contact data',
        showClose: true,
      });
    },
    async handleCall() {
      // this.button_call_active = true;
      if (this.buttonCallActive) {
        this.$confirm(this.$t('conversations.confirm.phone_call'), this.$t('general.confirmation'), {
          confirmButtonText: this.$t('general.yes'),
          cancelButtonText: this.$t('general.no'),
          type: 'warning',
          center: true,
          /* eslint-disable no-param-reassign */
          beforeClose: async (action, instance, cb) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true;
              this.showModalPhoneCall = true;
              instance.confirmButtonLoading = false;
              this.outgoingCall(this.selectedContact.contact);
            }
            cb();
          },
        });
      }
    },
    downloadCsv() {
      const label = 'template-contact.csv';
      let csv = 'phone,name';
      this.selectedContactFields.forEach((v) => {
        csv += `,${v.label}`;
      });
      const blob = new Blob([decodeURIComponent('%ef%bb%bf'), csv], { type: 'text/csv;charset=utf-8' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = label;
      link.click();
      URL.revokeObjectURL(link.href);
      // var a = document.createElement('a');
      // a.href = url;
      // a.download = 'template-contact.csv';
      // document.body.appendChild(a);
      // a.click();
      // const url = `${window.location.origin}/template/template-recipient.csv`;
      // const label = 'template-contact.csv';
      // axios.get(url, { responseType: 'blob' })
      //   .then((response) => {
      //     console.log(response.data);
      //     const blob = new Blob([response.data], { type: 'text/csv' });
      //     const link = document.createElement('a');
      //     link.href = URL.createObjectURL(blob);
      //     link.download = label;
      //     link.click();
      //     URL.revokeObjectURL(link.href);
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
    },
  },
};
</script>

<style lang="scss" scoped>
.chat-wrapper .chat-aside .aside-body .tab-content {
  max-height: calc(100vh - 340px);
}
</style>
<style>
[data-initials-contact]:before {
  background: #8CC8ED;
  color: white;
  opacity: 1;
  content: attr(data-initials-contact);
  display: inline-block;
  font-weight: bold;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 0.5em;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}
.dropify-wrapper {
  border-radius: 4px;
}
.dropify-wrapper .dropify-message p {
  font-size: 16px;
  color: #686868;
}

.modal-content {
  border: 2px solid #e8ebf1;
}
</style>
